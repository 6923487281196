




import { Component, Vue } from 'vue-property-decorator'
import BookingList from '@/components/BookingList.vue'
import { BookingStoreKind } from '../bookings'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { BookingList },
  name: 'Accounts'
})
export default class extends Vue {
  get userType (): BookingStoreKind { return BookingStoreKind.Account }
}
